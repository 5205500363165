import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import $ from 'jquery';

class mobilemenu extends Component {
    componentDidMount() {
        if ($(".main-menu").length && $(".mobile-nav__container").length) {
          let navContent = document.querySelector(".main-menu").innerHTML;
          let mobileNavContainer = document.querySelector(".mobile-nav__container");
          mobileNavContainer.innerHTML = navContent;
        }
        if ($(".sticky-header__content").length) {
          let navContent = document.querySelector(".main-menu").innerHTML;
          let mobileNavContainer = document.querySelector(".sticky-header__content");
          mobileNavContainer.innerHTML = navContent;
        }
        if ($(".mobile-nav__toggler").length) {
            $(".mobile-nav__toggler").on("click", function(e) {
                e.preventDefault();
                $(".mobile-nav__wrapper").toggleClass("expanded");
            });
        }
      }
    render() { 
        let selected_languge;
        if(localStorage.getItem('i18nextLng')==='en'){
           selected_languge='English';
        }
        if(localStorage.getItem('i18nextLng')==='ge'){
           selected_languge='ქართული';
        }
        if(localStorage.getItem('i18nextLng')==='ru'){
           selected_languge='Russian';
        }
      
         const { t } = this.props;
   
   const changeLanguage = (language) => {
     i18n.changeLanguage(language);
   };
        return <div>
             <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler" />
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler"><i className="far fa-times" /></span>
          <div className="logo-box">
            <a href="index.html" aria-label="logo image">
            <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70} viewBox="0 0 116.938 119.182">
  <g id="Group_25" data-name="Group 25" transform="translate(-476.715 -4369.456)">
    <g id="Group_23" data-name="Group 23">
      <path id="Path_29" data-name="Path 29" d="M562.37,4446.879h-25.2v-4.512q3.384,0,4.659-1.2a4.591,4.591,0,0,0,1.275-3.506v-16.476a27.744,27.744,0,0,0-.319-4.413,7.131,7.131,0,0,0-1.692-3.555,5.269,5.269,0,0,0-4.217-1.594,10.008,10.008,0,0,0-4.683,1.251,9.359,9.359,0,0,0-3.653,3.261,12.651,12.651,0,0,0-1.74,4.339,29.34,29.34,0,0,0-.368,5.026v8.434a33.33,33.33,0,0,0,.269,5.026,3.734,3.734,0,0,0,1.52,2.5,7.224,7.224,0,0,0,4.144.907v4.512h-25.7v-4.512a12.628,12.628,0,0,0,4.83-.686,3.13,3.13,0,0,0,1.913-2.035,18.792,18.792,0,0,0,.368-4.438v-40.4q0-3.678-1.177-4.928t-5.934-1.3v-4.413c5.868-.963,14.784-3.585,19.762-6.325v34.466a21.852,21.852,0,0,1,6.006-5.124,16.975,16.975,0,0,1,8.655-2.084,18.347,18.347,0,0,1,5.1.735,15.385,15.385,0,0,1,4.585,2.158,9.052,9.052,0,0,1,2.966,3.236,18.933,18.933,0,0,1,2.011,9.317v14.661q0,4.119,1.054,5.639t5.565,1.52Z" fill="#fff"/>
    </g>
    <g id="Group_24" data-name="Group 24">
      <path id="Path_30" data-name="Path 30" d="M503.288,4465.138c-2.984.3-3.259.511-3.259,3.629v14.564c0,3.118.313,3.3,3.259,3.591v1.267H492.265v-1.267c3.007-.374,3.258-.473,3.258-3.591v-7.19H484.48v7.19c0,3.1.323,3.266,3.221,3.591v1.267H476.715v-1.267c2.86-.312,3.245-.473,3.245-3.591v-14.564c0-3.118-.336-3.378-3.245-3.629v-1.266H487.7v1.266c-2.9.262-3.221.524-3.221,3.629v5.559h11.043v-5.559c0-3.1-.387-3.342-3.258-3.629v-1.266h11.023Z" fill="#fff"/>
      <path id="Path_31" data-name="Path 31" d="M516.77,4488.638a3.182,3.182,0,0,1-2.123-.76,3.564,3.564,0,0,1-.946-1.489c-1.448.962-3.281,2.249-4.149,2.249a4.8,4.8,0,0,1-4.9-4.748c0-1.93.937-2.933,2.947-3.692,2.233-.81,5.136-1.734,5.983-2.456v-1.332c0-2.194-.933-3.436-2.66-3.436a1.96,1.96,0,0,0-1.588.787,7.455,7.455,0,0,0-.937,2.456,1.324,1.324,0,0,1-1.425,1.128,2.113,2.113,0,0,1-2.011-1.84c0-.621.462-1.094,1.283-1.667a17.623,17.623,0,0,1,5.776-2.643,6.072,6.072,0,0,1,3.6,1.059,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.769.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.441c-.726.387-1.792.906-2.579,1.3-1.252.612-1.99,1.3-1.99,2.609a2.6,2.6,0,0,0,2.426,2.857,3.1,3.1,0,0,0,2.143-.861Z" fill="#fff"/>
      <path id="Path_32" data-name="Path 32" d="M531.308,4488.189h-9.431V4487c2.114-.224,2.314-.411,2.314-2.721v-8.049c0-2.08-.15-2.241-2.052-2.576v-1.081a29.944,29.944,0,0,0,6.223-1.461v4.067c1.552-2.359,2.978-3.979,4.63-3.979a2.153,2.153,0,0,1,2.339,2.088,3.254,3.254,0,0,1-1.685,2.787.925.925,0,0,1-1.094-.148c-.61-.537-1.083-1.057-1.732-1.057s-1.732.722-2.458,2.314v7.082c0,2.323.236,2.461,2.946,2.734Z" fill="#fff"/>
      <path id="Path_33" data-name="Path 33" d="M554.55,4472.824c-2.021.311-2.307.683-3.158,2.611-1.283,2.993-3.294,7.726-5.434,13.165h-1.4q-2.649-6.781-5.308-13.277c-.739-1.805-1.087-2.175-3.032-2.5v-1.18h8.75v1.18c-1.814.311-1.987.6-1.485,1.962.935,2.582,2.361,6.3,3.116,8.218.885-2.235,2.144-5.425,2.991-7.941.466-1.371.378-1.977-1.994-2.239v-1.18h6.955Z" fill="#fff"/>
      <path id="Path_34" data-name="Path 34" d="M555.108,4488.189V4487c2.138-.249,2.375-.411,2.375-3.028v-7.439c0-2.441-.161-2.591-2.089-2.868v-1.116a34.69,34.69,0,0,0,6.26-1.4v12.822c0,2.544.189,2.779,2.451,3.028v1.194Zm4.206-19.811a2.487,2.487,0,0,1-2.445-2.421,2.451,2.451,0,0,1,2.483-2.446,2.39,2.39,0,0,1,2.418,2.446A2.439,2.439,0,0,1,559.314,4468.378Z" fill="#fff"/>
      <path id="Path_35" data-name="Path 35" d="M573.449,4488.2a3.977,3.977,0,0,1-1.536.438c-2.711,0-4.274-1.386-4.274-4.647V4473.4h-2.46l-.2-.449,1.058-1.3h1.6v-3.118c1.075-.846,2.646-1.964,3.746-2.81l.513.221c-.05,1.207-.112,3.255-.088,5.707h3.924a1.451,1.451,0,0,1-.273,1.753H571.81v9.309c0,2.957,1.2,3.373,2,3.373a4.909,4.909,0,0,0,1.966-.453l.361,1.216Z" fill="#fff"/>
      <path id="Path_36" data-name="Path 36" d="M589.938,4488.638a3.182,3.182,0,0,1-2.123-.76,3.554,3.554,0,0,1-.946-1.489c-1.448.962-3.281,2.249-4.149,2.249a4.8,4.8,0,0,1-4.9-4.748c0-1.93.937-2.933,2.946-3.692,2.233-.81,5.136-1.734,5.983-2.456v-1.332c0-2.194-.933-3.436-2.66-3.436a1.96,1.96,0,0,0-1.588.787,7.477,7.477,0,0,0-.937,2.456,1.324,1.324,0,0,1-1.425,1.128,2.113,2.113,0,0,1-2.011-1.84c0-.621.462-1.094,1.283-1.667a17.619,17.619,0,0,1,5.777-2.643,6.071,6.071,0,0,1,3.6,1.059,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.769.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.441c-.725.387-1.792.906-2.579,1.3-1.251.612-1.99,1.3-1.99,2.609a2.6,2.6,0,0,0,2.426,2.857,3.1,3.1,0,0,0,2.143-.861Z" fill="#fff"/>
    </g>
    <path id="Path_37" data-name="Path 37" d="M558.515,4369.456c-11.907,1.992-19.233,8.947-19.671,17.283a15.347,15.347,0,0,0,6.127,13.047c.6-7.742,2.878-14.32,5.587-18.417-2.441,8.067-2.351,18.542-1.34,22.658a16.086,16.086,0,0,0,10.9-14.353C560.766,4381.483,555.426,4376.114,558.515,4369.456Z" fill="#fff"/>
  </g>
</svg>

            </a>
          </div>
          <div className="mobile-nav__container" />
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="agrikon-icon-email" />
              <a href="/cdn-cgi/l/email-protection#503e35353438353c2010313722393b3f3e7e333f3d"><span className="__cf_email__" data-cfemail="a0cec5c5c4c8c5ccd0e0c1c7d2c9cbcfce8ec3cfcd">[email&nbsp;protected]</span></a>
            </li>
            <li>
              <i className="agrikon-icon-telephone" />
              <a href="tel:666-888-0000">666 888 0000</a>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__language">
              <label className="sr-only" htmlFor="language-select">select language</label>
              <ul className="navbar-nav mr-auto">
                  <li className="nav-item dropdown">
                    <a style={{ color:"#fff" }} className="nav-link dropdown-toggle" href="/" id="dropdown09" data-toggle="dropdown" aria-expanded="false">
                      <span className="flag-icon flag-icon-ge mr-2">
                      </span>

                      {selected_languge}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                    
                     <div className="dropdown-item" onClick={() => changeLanguage("ge")}><span className="flag-icon flag-icon-ge"> </span>  ქართული</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("en")}><span className="flag-icon flag-icon-us"> </span>  English</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("ru")}><span className="flag-icon flag-icon-ru"> </span>  Russian</div>
                    </div>
                  </li>
                </ul>
            </div>
            <div className="mobile-nav__social">
              <a href="#" aria-label="twitter"><i className="fab fa-twitter" /></a>
              <a href="#" aria-label="facebook"><i className="fab fa-facebook-square" /></a>
              <a href="#" aria-label="pinterest"><i className="fab fa-pinterest-p" /></a>
              <a href="#" aria-label="instagram"><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
      </div>
        </div>;
    }
}
 
export default withTranslation()(mobilemenu);
import React, { Component } from 'react';
import Menu from '../components/menu'
import Mobilemenu from '../components/mobilemenu'
import Footer from '../components/footer'
class Home extends Component {
   

    render() {
        return (
            <div>
                 <div>
        <div className="page-wrapper">
            <Menu/>
          <section className="main-slider">
            <div className="swiper-container thm-swiper__slider" data-swiper-options="{
        &quot;slidesPerView&quot;: 1,
        &quot;loop&quot;: true,
        &quot;effect&quot;: &quot;fade&quot;,
        &quot;autoplay&quot;: {
            &quot;delay&quot;: 5000
        },
        &quot;navigation&quot;: {
            &quot;nextEl&quot;: &quot;#main-slider__swiper-button-next&quot;,
            &quot;prevEl&quot;: &quot;#main-slider__swiper-button-prev&quot;
        }
    }">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/main-slider-1-1.jpg)'}}>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-7 col-lg-7">
                        <span className="tagline">Welcome to Agriculture Farm</span>
                        <h2><span>Agriculture</span> <br /> Eco Farming </h2>
                        <p>There are many of passages of lorem Ipsum, but the majori have <br /> suffered alteration in some form.</p>
                        <a href="/" className=" thm-btn">სრულად ნახვა</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="image-layer" style={{backgroundImage: 'url(assets/images/main-slider/main-slider-1-2.jpg)'}}>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-7 col-lg-7">
                        <span className="tagline">Welcome to Agriculture Farm</span>
                        <h2><span>Agriculture</span><br /> &amp; Eco Farming</h2>
                        <p>There are many of passages of lorem Ipsum, but the majori have <br /> suffered alteration in some form.</p>
                        <a href="/" className=" thm-btn">სრულად ნახვა</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-slider__nav">
                <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="agrikon-icon-left-arrow" />
                </div>
                <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="agrikon-icon-right-arrow" /></div>
              </div>
            </div>
          </section>
          <section className="service-two">
            <div className="service-two__bottom-curv" />
            <div className="container">
              <div className="block-title text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40.617" height="40.335" viewBox="0 0 89.617 111.335">
                  <g id="Group_7" data-name="Group 7" transform="translate(-388.209 -2862.282)">
                    <rect id="Rectangle_3" data-name="Rectangle 3" width="89.617" height="111.335" transform="translate(388.209 2862.282)" fill="#252525" />
                    <g id="Group_6" data-name="Group 6">
                      <g id="Group_5" data-name="Group 5">
                        <path id="Path_10" data-name="Path 10" d="M460.869,2956.66h-25.2v-4.511q3.384,0,4.659-1.2a4.591,4.591,0,0,0,1.274-3.506v-16.476a27.625,27.625,0,0,0-.319-4.413,7.126,7.126,0,0,0-1.691-3.555,5.272,5.272,0,0,0-4.217-1.594,10.015,10.015,0,0,0-4.683,1.25,9.365,9.365,0,0,0-3.653,3.261,12.635,12.635,0,0,0-1.741,4.34,29.453,29.453,0,0,0-.368,5.026v8.434a33.306,33.306,0,0,0,.27,5.026,3.73,3.73,0,0,0,1.52,2.5,7.222,7.222,0,0,0,4.144.907v4.511H405.165v-4.511a12.631,12.631,0,0,0,4.83-.686,3.129,3.129,0,0,0,1.912-2.035,18.743,18.743,0,0,0,.368-4.438v-40.4q0-3.678-1.177-4.928t-5.933-1.3v-4.413c5.868-.963,14.784-3.585,19.761-6.325v34.466a21.857,21.857,0,0,1,6.007-5.125,16.98,16.98,0,0,1,8.655-2.083,18.33,18.33,0,0,1,5.1.735,15.4,15.4,0,0,1,4.585,2.157,9.071,9.071,0,0,1,2.967,3.237,18.933,18.933,0,0,1,2.01,9.317v14.661q0,4.119,1.055,5.639t5.565,1.52Z" fill="#fff" />
                      </g>
                      <path id="Path_11" data-name="Path 11" d="M457.014,2879.238c-11.907,1.992-19.233,8.947-19.671,17.283a15.347,15.347,0,0,0,6.127,13.047c.6-7.743,2.878-14.321,5.587-18.417-2.441,8.067-2.351,18.542-1.34,22.657a16.086,16.086,0,0,0,10.9-14.352C459.265,2891.264,453.924,2885.9,457.014,2879.238Z" fill="#5ca120" />
                    </g>
                  </g>
                </svg>
                <p>პროექტები</p>
                <h3>ჩვენი პროექტები</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="service-two__card">
                    <div className="service-two__card-image">
                      <img src="assets/images/services/service-2-1.jpg" alt="" />
                    </div>
                    <div className="service-two__card-content">
                      <div className="service-two__card-icon">
                        <i className="agrikon-icon-tractor" />
                      </div>
                      <h3><a href="service-details.html">მოცვი</a></h3>
                      <p>
                        Lorem Ipsum საბეჭდი და ტიპოგრაფიული ინდუსტრიის უშინაარსო ტექსტია.
                        იგი სტანდარტად 1500-იანი წლებიდან იქცა, როდესაც უცნობმა მბეჭდავმა...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="service-two__card">
                    <div className="service-two__card-image">
                      <img src="assets/images/services/service-2-2.jpg" alt="" />
                    </div>
                    <div className="service-two__card-content">
                      <div className="service-two__card-icon">
                        <i className="agrikon-icon-organic-food" />
                      </div>
                      <h3><a href="service-details.html">სოკო</a></h3>
                      <p>
                        Lorem Ipsum საბეჭდი და ტიპოგრაფიული ინდუსტრიის უშინაარსო ტექსტია.
                        იგი სტანდარტად 1500-იანი წლებიდან იქცა, როდესაც უცნობმა მბეჭდავმა...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="service-two__card">
                    <div className="service-two__card-image">
                      <img src="assets/images/services/service-2-3.jpg" alt="" />
                    </div>
                    <div className="service-two__card-content">
                      <div className="service-two__card-icon">
                        <i className="agrikon-icon-vegetable" />
                      </div>
                      <h3><a href="service-details.html">ბიო სვანეთი</a></h3>
                      <p>
                        Lorem Ipsum საბეჭდი და ტიპოგრაფიული ინდუსტრიის უშინაარსო ტექსტია.
                        იგი სტანდარტად 1500-იანი წლებიდან იქცა, როდესაც უცნობმა მბეჭდავმა...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="call-to-action__three jarallax" data-jarallax data-speed="0.3" data-imgposition="50% 50%">
            <img className="call-to-action__three__bg jarallax-img" src="assets/images/backgrounds/cta-1-bg-1.jpg" alt="parallax " />
            <div className="container">
              <div className="row">
                <div className="col-lg-5 wow fadeInLeft" data-wow-duration="1500ms">
                  <div className="call-to-action__three-image">
                    <img src="assets/images/resources/cta-3-1.jpg" alt="" />
                    <img src="assets/images/resources/cta-3-2.jpg" alt="" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="call-to-action__three-content">
                    <h3>ვაწარმოებთ ხარისხიან პროდუქტს
                    </h3>
                    <a href="about.html" className="thm-btn">Discover More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gray-boxed-wrapper home-one__boxed">
            <img src="assets/images/icons/home-1-blog-bg.png" alt="" className="home-one__boxed-bg" />
            <div className="blog-home-two blog-home-one">
              <div className="container">
                <div className="row top-row">
                  <div className="col-lg-6">
                    <div className="block-title">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40.617" height="40.335" viewBox="0 0 89.617 111.335">
                        <g id="Group_7" data-name="Group 7" transform="translate(-388.209 -2862.282)">
                          <rect id="Rectangle_3" data-name="Rectangle 3" width="89.617" height="111.335" transform="translate(388.209 2862.282)" fill="#252525" />
                          <g id="Group_6" data-name="Group 6">
                            <g id="Group_5" data-name="Group 5">
                              <path id="Path_10" data-name="Path 10" d="M460.869,2956.66h-25.2v-4.511q3.384,0,4.659-1.2a4.591,4.591,0,0,0,1.274-3.506v-16.476a27.625,27.625,0,0,0-.319-4.413,7.126,7.126,0,0,0-1.691-3.555,5.272,5.272,0,0,0-4.217-1.594,10.015,10.015,0,0,0-4.683,1.25,9.365,9.365,0,0,0-3.653,3.261,12.635,12.635,0,0,0-1.741,4.34,29.453,29.453,0,0,0-.368,5.026v8.434a33.306,33.306,0,0,0,.27,5.026,3.73,3.73,0,0,0,1.52,2.5,7.222,7.222,0,0,0,4.144.907v4.511H405.165v-4.511a12.631,12.631,0,0,0,4.83-.686,3.129,3.129,0,0,0,1.912-2.035,18.743,18.743,0,0,0,.368-4.438v-40.4q0-3.678-1.177-4.928t-5.933-1.3v-4.413c5.868-.963,14.784-3.585,19.761-6.325v34.466a21.857,21.857,0,0,1,6.007-5.125,16.98,16.98,0,0,1,8.655-2.083,18.33,18.33,0,0,1,5.1.735,15.4,15.4,0,0,1,4.585,2.157,9.071,9.071,0,0,1,2.967,3.237,18.933,18.933,0,0,1,2.01,9.317v14.661q0,4.119,1.055,5.639t5.565,1.52Z" fill="#fff" />
                            </g>
                            <path id="Path_11" data-name="Path 11" d="M457.014,2879.238c-11.907,1.992-19.233,8.947-19.671,17.283a15.347,15.347,0,0,0,6.127,13.047c.6-7.743,2.878-14.321,5.587-18.417-2.441,8.067-2.351,18.542-1.34,22.657a16.086,16.086,0,0,0,10.9-14.352C459.265,2891.264,453.924,2885.9,457.014,2879.238Z" fill="#5ca120" />
                          </g>
                        </g>
                      </svg>
                      <p>სიახლეები</p>
                      <h3>ბოლო სიახლეები</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-4">
                    <div className="blog-card">
                      <div className="blog-card__image">
                        <img src="assets/images/blog/blog-grid-1.jpg" alt="Best Way to Do Eco and Agriculture" />
                      </div>
                      <div className="blog-card__content">
                        <div className="blog-card__date">18 ნოე</div>
                        <h3><a href="/">ლორემ იფსუმ დორემ ლორემ</a></h3>
                        <a href="/" className="thm-btn">სრულად ნახვა</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div className="blog-card">
                      <div className="blog-card__image">
                        <img src="assets/images/blog/blog-grid-2.jpg" alt="Leverage agile frameworks to provide" />
                      </div>
                      <div className="blog-card__content">
                        <div className="blog-card__date">19 ნოე</div>
                        <h3><a href="/">ლორემ იფსუმ დორემ ლორემ</a></h3>
                        <a href="/" className="thm-btn">სრულად ნახვა</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div className="blog-card">
                      <div className="blog-card__image">
                        <img src="assets/images/blog/blog-grid-3.jpg" alt="Organically grow the holistic world view" />
                      </div>
                      <div className="blog-card__content">
                        <div className="blog-card__date">20 ნოე</div>
                        <h3><a href="/">ლორემ იფსუმ დორემ ლორემ</a></h3>
                        <a href="/" className="thm-btn">სრულად ნახვა</a>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </section>
          <section className="contact-two">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                  <div className="contact-two__image">
                    <div className="contact-two__image-bubble-1" />
                    <div className="contact-two__image-bubble-2" />
                    <div className="contact-two__image-bubble-3" />
                    <img src="assets/images/resources/contact-1-1.jpg" className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                  <div className="contact-two__content">
                    <div className="block-title">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40.617" height="40.335" viewBox="0 0 89.617 111.335">
                        <g id="Group_7" data-name="Group 7" transform="translate(-388.209 -2862.282)">
                          <rect id="Rectangle_3" data-name="Rectangle 3" width="89.617" height="111.335" transform="translate(388.209 2862.282)" fill="#252525" />
                          <g id="Group_6" data-name="Group 6">
                            <g id="Group_5" data-name="Group 5">
                              <path id="Path_10" data-name="Path 10" d="M460.869,2956.66h-25.2v-4.511q3.384,0,4.659-1.2a4.591,4.591,0,0,0,1.274-3.506v-16.476a27.625,27.625,0,0,0-.319-4.413,7.126,7.126,0,0,0-1.691-3.555,5.272,5.272,0,0,0-4.217-1.594,10.015,10.015,0,0,0-4.683,1.25,9.365,9.365,0,0,0-3.653,3.261,12.635,12.635,0,0,0-1.741,4.34,29.453,29.453,0,0,0-.368,5.026v8.434a33.306,33.306,0,0,0,.27,5.026,3.73,3.73,0,0,0,1.52,2.5,7.222,7.222,0,0,0,4.144.907v4.511H405.165v-4.511a12.631,12.631,0,0,0,4.83-.686,3.129,3.129,0,0,0,1.912-2.035,18.743,18.743,0,0,0,.368-4.438v-40.4q0-3.678-1.177-4.928t-5.933-1.3v-4.413c5.868-.963,14.784-3.585,19.761-6.325v34.466a21.857,21.857,0,0,1,6.007-5.125,16.98,16.98,0,0,1,8.655-2.083,18.33,18.33,0,0,1,5.1.735,15.4,15.4,0,0,1,4.585,2.157,9.071,9.071,0,0,1,2.967,3.237,18.933,18.933,0,0,1,2.01,9.317v14.661q0,4.119,1.055,5.639t5.565,1.52Z" fill="#fff" />
                            </g>
                            <path id="Path_11" data-name="Path 11" d="M457.014,2879.238c-11.907,1.992-19.233,8.947-19.671,17.283a15.347,15.347,0,0,0,6.127,13.047c.6-7.743,2.878-14.321,5.587-18.417-2.441,8.067-2.351,18.542-1.34,22.657a16.086,16.086,0,0,0,10.9-14.352C459.265,2891.264,453.924,2885.9,457.014,2879.238Z" fill="#5ca120" />
                          </g>
                        </g>
                      </svg>
                      <p>დაგვიკავშირდით</p>
                      <h3>დაგვიტოვეთ შეტყობინება
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <form action="assets/inc/sendemail.php" className="contact-one__form contact-form-validated">
                    <div className="row">
                      <div className="col-lg-12">
                        <input type="text" name="name" placeholder="სახელი გვარი" />
                      </div>
                      <div className="col-lg-12">
                        <input type="text" name="email" placeholder="ელ.ფოსტა" />
                      </div>
                      <div className="col-lg-12">
                        <input type="text" name="phone" placeholder="ტელეფონის ნომერი" />
                      </div>
                      <div className="col-lg-12">
                        <textarea name="message" placeholder="შეტყობინება" defaultValue={""} />
                      </div>
                      <div className="col-lg-12">
                        <button type="submit" className="thm-btn">გაგზავნა</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer/>
          <Mobilemenu/>
        </div>
      </div>
            </div>
        );
    }
}


export default Home;
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import $ from 'jquery';
import '../assets/js/swiper.min.js';


class menu extends Component {

  componentDidMount() {
    if ($(".main-menu").length && $(".mobile-nav__container").length) {
      let navContent = document.querySelector(".main-menu").innerHTML;
      let mobileNavContainer = document.querySelector(".mobile-nav__container");
      mobileNavContainer.innerHTML = navContent;
    }
    if ($(".sticky-header__content").length) {
      let navContent = document.querySelector(".main-menu").innerHTML;
      let mobileNavContainer = document.querySelector(".sticky-header__content");
      mobileNavContainer.innerHTML = navContent;
    }
    const swiperElm = document.querySelectorAll(".thm-swiper__slider");
    swiperElm.forEach(function(swiperelm) {
        const swiperOptions = JSON.parse(swiperelm.dataset.swiperOptions);
        let thmSwiperSlider = new Swiper(swiperelm, swiperOptions);
    });
  }
    render() {
    
    let selected_languge;
     if(localStorage.getItem('i18nextLng')==='en'){
        selected_languge='English';
     }
     if(localStorage.getItem('i18nextLng')==='ka'){
        selected_languge='ქართული';
     }
     if(localStorage.getItem('i18nextLng')==='ru'){
        selected_languge='Russian';
     }
     console.log(localStorage.getItem('i18nextLng'));
   
      const { t } = this.props;

const changeLanguage = (language) => {
  i18n.changeLanguage(language);
};
        return (
            <div>
<div>
        <header className="main-header">
          <div className="topbar">
            <div className="container">
              <div className="topbar__left">
                <div className="topbar__social">
                  <a href="/"><i  className="fab fa-facebook-square"></i></a> 
                  <a href="/"><i  className="fab fa-twitter"></i></a>
                  <a href="/"><i  className="fab fa-pinterest-p"></i></a>
                  <a href="/"><i  className="fab fa-instagram"></i></a>
                </div>
                <p>{t('welcome')}</p>
              </div>
              <div className="topbar__right">
                <a href="/">
                  <i className="agrikon-icon-email" />
                  info@harvita.ge
                </a>
              </div>
            </div>
          </div>
          <nav className="main-menu">

            <div className="container">
              <div className="logo-box">
                <a href="index.html" aria-label="logo image">
                  <svg xmlns="http://www.w3.org/2000/svg" width="90.851" height="90.645" viewBox="0 0 150.851 152.645">
                    <rect id="Rectangle_12" data-name="Rectangle 12" width="150.851" height="152.645" fill="#fff" />
                    <g id="Group_32" data-name="Group 32" transform="translate(-229.299 -2127.889)">
                      <g id="Group_30" data-name="Group 30">
                        <path id="Path_47" data-name="Path 47" d="M331.911,2222.268h-25.2v-4.511q3.383,0,4.658-1.2a4.585,4.585,0,0,0,1.275-3.5v-16.476a27.75,27.75,0,0,0-.319-4.414,7.138,7.138,0,0,0-1.691-3.555,5.271,5.271,0,0,0-4.217-1.593,10.012,10.012,0,0,0-4.683,1.25,9.37,9.37,0,0,0-3.654,3.261,12.659,12.659,0,0,0-1.74,4.339,29.452,29.452,0,0,0-.368,5.027v8.434a33.32,33.32,0,0,0,.27,5.026,3.727,3.727,0,0,0,1.52,2.5,7.218,7.218,0,0,0,4.143.908v4.511H276.207v-4.511a12.628,12.628,0,0,0,4.83-.687,3.126,3.126,0,0,0,1.912-2.035,18.783,18.783,0,0,0,.368-4.437v-40.4q0-3.676-1.177-4.928t-5.933-1.3v-4.413c5.868-.963,14.784-3.585,19.761-6.326V2187.7a21.875,21.875,0,0,1,6.007-5.124,16.972,16.972,0,0,1,8.655-2.084,18.336,18.336,0,0,1,5.1.735,15.4,15.4,0,0,1,4.585,2.158,9.068,9.068,0,0,1,2.967,3.237,18.926,18.926,0,0,1,2.01,9.316V2210.6q0,4.119,1.054,5.639t5.566,1.52Z" fill="#252525" />
                      </g>
                      <g id="Group_31" data-name="Group 31">
                        <path id="Path_48" data-name="Path 48" d="M272.828,2240.528c-2.984.3-3.258.511-3.258,3.629v14.563c0,3.119.312,3.3,3.258,3.592v1.266H261.8v-1.266c3.008-.374,3.258-.473,3.258-3.592v-7.19H254.02v7.19c0,3.1.323,3.266,3.221,3.592v1.266H246.255v-1.266c2.86-.313,3.246-.473,3.246-3.592v-14.563c0-3.118-.337-3.379-3.246-3.629v-1.267h10.986v1.267c-2.9.262-3.221.523-3.221,3.629v5.559h11.043v-5.559c0-3.106-.387-3.343-3.258-3.629v-1.267h11.023Z" fill="#252525" />
                        <path id="Path_49" data-name="Path 49" d="M286.311,2264.027a3.181,3.181,0,0,1-2.123-.759,3.565,3.565,0,0,1-.947-1.489c-1.448.961-3.281,2.248-4.149,2.248a4.8,4.8,0,0,1-4.9-4.747c0-1.931.937-2.933,2.946-3.693,2.233-.81,5.136-1.734,5.984-2.456V2251.8c0-2.194-.934-3.436-2.66-3.436a1.955,1.955,0,0,0-1.588.788,7.449,7.449,0,0,0-.938,2.456,1.325,1.325,0,0,1-1.425,1.128,2.114,2.114,0,0,1-2.011-1.841c0-.621.462-1.094,1.284-1.667a17.592,17.592,0,0,1,5.776-2.642,6.067,6.067,0,0,1,3.6,1.058,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.77.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.44c-.726.387-1.792.905-2.58,1.3-1.251.612-1.99,1.3-1.99,2.61a2.6,2.6,0,0,0,2.426,2.856,3.1,3.1,0,0,0,2.144-.861Z" fill="#252525" />
                        <path id="Path_50" data-name="Path 50" d="M300.848,2263.578h-9.431v-1.193c2.114-.225,2.314-.412,2.314-2.722v-8.049c0-2.08-.15-2.241-2.052-2.575v-1.082a29.988,29.988,0,0,0,6.223-1.46v4.066c1.553-2.359,2.978-3.978,4.63-3.978a2.153,2.153,0,0,1,2.34,2.087,3.254,3.254,0,0,1-1.685,2.787.925.925,0,0,1-1.1-.148c-.609-.537-1.083-1.057-1.732-1.057s-1.732.722-2.458,2.314v7.082c0,2.323.236,2.462,2.946,2.735Z" fill="#252525" />
                        <path id="Path_51" data-name="Path 51" d="M324.09,2248.214c-2.02.31-2.306.683-3.157,2.611-1.284,2.993-3.3,7.725-5.434,13.165h-1.4q-2.649-6.781-5.309-13.277c-.739-1.8-1.087-2.176-3.032-2.5v-1.181h8.751v1.181c-1.815.31-1.987.6-1.486,1.962.935,2.581,2.361,6.3,3.116,8.217.885-2.235,2.144-5.424,2.991-7.94.466-1.372.378-1.977-1.993-2.239v-1.181h6.954Z" fill="#252525" />
                        <path id="Path_52" data-name="Path 52" d="M324.648,2263.578v-1.193c2.138-.249,2.375-.412,2.375-3.029v-7.439c0-2.441-.16-2.59-2.089-2.867v-1.117a34.62,34.62,0,0,0,6.261-1.4v12.822c0,2.544.189,2.78,2.45,3.029v1.193Zm4.207-19.81a2.487,2.487,0,0,1-2.445-2.421,2.45,2.45,0,0,1,2.482-2.447,2.391,2.391,0,0,1,2.419,2.447A2.44,2.44,0,0,1,328.855,2243.768Z" fill="#252525" />
                        <path id="Path_53" data-name="Path 53" d="M342.989,2263.589a3.959,3.959,0,0,1-1.536.438c-2.71,0-4.274-1.386-4.274-4.646v-10.6h-2.46l-.2-.449,1.059-1.3h1.6v-3.118c1.076-.845,2.647-1.964,3.747-2.809l.512.22c-.05,1.207-.112,3.255-.088,5.707h3.925a1.451,1.451,0,0,1-.273,1.753H341.35v9.31c0,2.957,1.2,3.372,2,3.372a4.9,4.9,0,0,0,1.966-.453l.361,1.216Z" fill="#252525" />
                        <path id="Path_54" data-name="Path 54" d="M359.479,2264.027a3.181,3.181,0,0,1-2.123-.759,3.565,3.565,0,0,1-.947-1.489c-1.448.961-3.281,2.248-4.149,2.248a4.8,4.8,0,0,1-4.9-4.747c0-1.931.937-2.933,2.946-3.693,2.233-.81,5.136-1.734,5.984-2.456V2251.8c0-2.194-.934-3.436-2.66-3.436a1.957,1.957,0,0,0-1.588.788,7.429,7.429,0,0,0-.937,2.456,1.327,1.327,0,0,1-1.426,1.128,2.114,2.114,0,0,1-2.011-1.841c0-.621.463-1.094,1.284-1.667a17.592,17.592,0,0,1,5.776-2.642,6.067,6.067,0,0,1,3.6,1.058,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.77.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.44c-.726.387-1.792.905-2.58,1.3-1.251.612-1.99,1.3-1.99,2.61a2.6,2.6,0,0,0,2.426,2.856,3.1,3.1,0,0,0,2.144-.861Z" fill="#252525" />
                      </g>
                      <path id="Path_55" data-name="Path 55" d="M328.055,2144.846c-11.907,1.991-19.233,8.946-19.671,17.283a15.345,15.345,0,0,0,6.128,13.046c.6-7.742,2.878-14.32,5.586-18.416-2.441,8.067-2.351,18.542-1.339,22.657a16.085,16.085,0,0,0,10.9-14.352C330.307,2156.872,324.966,2151.5,328.055,2144.846Z" fill="#5ca120" />
                    </g>
                  </svg>
                </a>
                <span className="fa fa-bars mobile-nav__toggler" />
              </div>
              <ul className="main-menu__list">
                <li className="dropdown">
                  <a href="/">{t("home")}</a>
                </li>
                <li className="dropdown"><a href="/about">{t('Aboutus')}</a>
                  <ul>
                    <li><a href="services.html">ვინ ვართ ჩვენ?</a></li>
                    <li><a href="service-details.html">ხედვა</a></li>
                    <li><a href="contact.html">გუნდი</a></li>
                    <li><a href="contact.html">პრესა</a></li>
                    <li><a href="contact.html">სიახლეები</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('projects')}</a>
                  <ul>
                    <li><a href="projects.html">მოცვი</a></li>
                    <li><a href="project-details.html">სოკო</a></li>
                    <li><a href="project-details.html">ბიო სვანეთი</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="index.html">{t('catalog')}</a>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('career')}</a>
                  <ul>
                    <li><a href="projects.html">დასაქმება</a></li>
                    <li><a href="project-details.html">სტაჟირების პროგრამა</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('gallery')}</a>
                  <ul>
                    <li><a href="projects.html">{t('photo')}</a></li>
                    <li><a href="project-details.html">{t('video')}</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="/">{t('contact')}</a>
                </li>
              </ul>
              <div className="main-header__info">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="dropdown09" data-toggle="dropdown" aria-expanded="false">
                     

                    { localStorage.getItem('i18nextLng')==='ka' ? (<span className="flag-icon flag-icon-ge mr-2"> </span>):('')}
                    { localStorage.getItem('i18nextLng')==='en' ? (<span className="flag-icon flag-icon-us mr-2"> </span>):('')}
                    { localStorage.getItem('i18nextLng')==='ru' ? (<span className="flag-icon flag-icon-ru mr-2"> </span>):('')}

                      {selected_languge}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                    
                     <div className="dropdown-item" onClick={() => changeLanguage("ka")}><span className="flag-icon flag-icon-ge"> </span>  ქართული</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("en")}><span className="flag-icon flag-icon-us"> </span>  English</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("ru")}><span className="flag-icon flag-icon-ru"> </span>  Russian</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          
          </nav>
        </header>
        <div className="stricky-header stricked-menu main-menu">
          
        <div className="container">
              <div className="logo-box">
                <a href="index.html" aria-label="logo image">
                  <svg xmlns="http://www.w3.org/2000/svg" width="90.851" height="90.645" viewBox="0 0 150.851 152.645">
                    <rect id="Rectangle_12" data-name="Rectangle 12" width="150.851" height="152.645" fill="#fff" />
                    <g id="Group_32" data-name="Group 32" transform="translate(-229.299 -2127.889)">
                      <g id="Group_30" data-name="Group 30">
                        <path id="Path_47" data-name="Path 47" d="M331.911,2222.268h-25.2v-4.511q3.383,0,4.658-1.2a4.585,4.585,0,0,0,1.275-3.5v-16.476a27.75,27.75,0,0,0-.319-4.414,7.138,7.138,0,0,0-1.691-3.555,5.271,5.271,0,0,0-4.217-1.593,10.012,10.012,0,0,0-4.683,1.25,9.37,9.37,0,0,0-3.654,3.261,12.659,12.659,0,0,0-1.74,4.339,29.452,29.452,0,0,0-.368,5.027v8.434a33.32,33.32,0,0,0,.27,5.026,3.727,3.727,0,0,0,1.52,2.5,7.218,7.218,0,0,0,4.143.908v4.511H276.207v-4.511a12.628,12.628,0,0,0,4.83-.687,3.126,3.126,0,0,0,1.912-2.035,18.783,18.783,0,0,0,.368-4.437v-40.4q0-3.676-1.177-4.928t-5.933-1.3v-4.413c5.868-.963,14.784-3.585,19.761-6.326V2187.7a21.875,21.875,0,0,1,6.007-5.124,16.972,16.972,0,0,1,8.655-2.084,18.336,18.336,0,0,1,5.1.735,15.4,15.4,0,0,1,4.585,2.158,9.068,9.068,0,0,1,2.967,3.237,18.926,18.926,0,0,1,2.01,9.316V2210.6q0,4.119,1.054,5.639t5.566,1.52Z" fill="#252525" />
                      </g>
                      <g id="Group_31" data-name="Group 31">
                        <path id="Path_48" data-name="Path 48" d="M272.828,2240.528c-2.984.3-3.258.511-3.258,3.629v14.563c0,3.119.312,3.3,3.258,3.592v1.266H261.8v-1.266c3.008-.374,3.258-.473,3.258-3.592v-7.19H254.02v7.19c0,3.1.323,3.266,3.221,3.592v1.266H246.255v-1.266c2.86-.313,3.246-.473,3.246-3.592v-14.563c0-3.118-.337-3.379-3.246-3.629v-1.267h10.986v1.267c-2.9.262-3.221.523-3.221,3.629v5.559h11.043v-5.559c0-3.106-.387-3.343-3.258-3.629v-1.267h11.023Z" fill="#252525" />
                        <path id="Path_49" data-name="Path 49" d="M286.311,2264.027a3.181,3.181,0,0,1-2.123-.759,3.565,3.565,0,0,1-.947-1.489c-1.448.961-3.281,2.248-4.149,2.248a4.8,4.8,0,0,1-4.9-4.747c0-1.931.937-2.933,2.946-3.693,2.233-.81,5.136-1.734,5.984-2.456V2251.8c0-2.194-.934-3.436-2.66-3.436a1.955,1.955,0,0,0-1.588.788,7.449,7.449,0,0,0-.938,2.456,1.325,1.325,0,0,1-1.425,1.128,2.114,2.114,0,0,1-2.011-1.841c0-.621.462-1.094,1.284-1.667a17.592,17.592,0,0,1,5.776-2.642,6.067,6.067,0,0,1,3.6,1.058,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.77.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.44c-.726.387-1.792.905-2.58,1.3-1.251.612-1.99,1.3-1.99,2.61a2.6,2.6,0,0,0,2.426,2.856,3.1,3.1,0,0,0,2.144-.861Z" fill="#252525" />
                        <path id="Path_50" data-name="Path 50" d="M300.848,2263.578h-9.431v-1.193c2.114-.225,2.314-.412,2.314-2.722v-8.049c0-2.08-.15-2.241-2.052-2.575v-1.082a29.988,29.988,0,0,0,6.223-1.46v4.066c1.553-2.359,2.978-3.978,4.63-3.978a2.153,2.153,0,0,1,2.34,2.087,3.254,3.254,0,0,1-1.685,2.787.925.925,0,0,1-1.1-.148c-.609-.537-1.083-1.057-1.732-1.057s-1.732.722-2.458,2.314v7.082c0,2.323.236,2.462,2.946,2.735Z" fill="#252525" />
                        <path id="Path_51" data-name="Path 51" d="M324.09,2248.214c-2.02.31-2.306.683-3.157,2.611-1.284,2.993-3.3,7.725-5.434,13.165h-1.4q-2.649-6.781-5.309-13.277c-.739-1.8-1.087-2.176-3.032-2.5v-1.181h8.751v1.181c-1.815.31-1.987.6-1.486,1.962.935,2.581,2.361,6.3,3.116,8.217.885-2.235,2.144-5.424,2.991-7.94.466-1.372.378-1.977-1.993-2.239v-1.181h6.954Z" fill="#252525" />
                        <path id="Path_52" data-name="Path 52" d="M324.648,2263.578v-1.193c2.138-.249,2.375-.412,2.375-3.029v-7.439c0-2.441-.16-2.59-2.089-2.867v-1.117a34.62,34.62,0,0,0,6.261-1.4v12.822c0,2.544.189,2.78,2.45,3.029v1.193Zm4.207-19.81a2.487,2.487,0,0,1-2.445-2.421,2.45,2.45,0,0,1,2.482-2.447,2.391,2.391,0,0,1,2.419,2.447A2.44,2.44,0,0,1,328.855,2243.768Z" fill="#252525" />
                        <path id="Path_53" data-name="Path 53" d="M342.989,2263.589a3.959,3.959,0,0,1-1.536.438c-2.71,0-4.274-1.386-4.274-4.646v-10.6h-2.46l-.2-.449,1.059-1.3h1.6v-3.118c1.076-.845,2.647-1.964,3.747-2.809l.512.22c-.05,1.207-.112,3.255-.088,5.707h3.925a1.451,1.451,0,0,1-.273,1.753H341.35v9.31c0,2.957,1.2,3.372,2,3.372a4.9,4.9,0,0,0,1.966-.453l.361,1.216Z" fill="#252525" />
                        <path id="Path_54" data-name="Path 54" d="M359.479,2264.027a3.181,3.181,0,0,1-2.123-.759,3.565,3.565,0,0,1-.947-1.489c-1.448.961-3.281,2.248-4.149,2.248a4.8,4.8,0,0,1-4.9-4.747c0-1.931.937-2.933,2.946-3.693,2.233-.81,5.136-1.734,5.984-2.456V2251.8c0-2.194-.934-3.436-2.66-3.436a1.957,1.957,0,0,0-1.588.788,7.429,7.429,0,0,0-.937,2.456,1.327,1.327,0,0,1-1.426,1.128,2.114,2.114,0,0,1-2.011-1.841c0-.621.463-1.094,1.284-1.667a17.592,17.592,0,0,1,5.776-2.642,6.067,6.067,0,0,1,3.6,1.058,5.1,5.1,0,0,1,2.005,4.51v6.734c0,1.77.612,2.244,1.237,2.244a2.868,2.868,0,0,0,1.225-.338l.4,1.193Zm-3.193-9.44c-.726.387-1.792.905-2.58,1.3-1.251.612-1.99,1.3-1.99,2.61a2.6,2.6,0,0,0,2.426,2.856,3.1,3.1,0,0,0,2.144-.861Z" fill="#252525" />
                      </g>
                      <path id="Path_55" data-name="Path 55" d="M328.055,2144.846c-11.907,1.991-19.233,8.946-19.671,17.283a15.345,15.345,0,0,0,6.128,13.046c.6-7.742,2.878-14.32,5.586-18.416-2.441,8.067-2.351,18.542-1.339,22.657a16.085,16.085,0,0,0,10.9-14.352C330.307,2156.872,324.966,2151.5,328.055,2144.846Z" fill="#5ca120" />
                    </g>
                  </svg>
                </a>
                <span className="fa fa-bars mobile-nav__toggler" />
              </div>
              <ul className="main-menu__list">
                <li className="dropdown">
                  <a href="/">{t("home")}</a>
                </li>
                <li className="dropdown"><a href="/about">{t('Aboutus')}</a>
                  <ul>
                    <li><a href="services.html">ვინ ვართ ჩვენ?</a></li>
                    <li><a href="service-details.html">ხედვა</a></li>
                    <li><a href="contact.html">გუნდი</a></li>
                    <li><a href="contact.html">პრესა</a></li>
                    <li><a href="contact.html">სიახლეები</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('projects')}</a>
                  <ul>
                    <li><a href="projects.html">მოცვი</a></li>
                    <li><a href="project-details.html">სოკო</a></li>
                    <li><a href="project-details.html">ბიო სვანეთი</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="index.html">{t('catalog')}</a>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('career')}</a>
                  <ul>
                    <li><a href="projects.html">დასაქმება</a></li>
                    <li><a href="project-details.html">სტაჟირების პროგრამა</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="projects.html">{t('gallery')}</a>
                  <ul>
                    <li><a href="projects.html">{t('photo')}</a></li>
                    <li><a href="project-details.html">{t('video')}</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="/">{t('contact')}</a>
                </li>
              </ul>
              <div className="main-header__info">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="dropdown09" data-toggle="dropdown" aria-expanded="false">
                     

                    { localStorage.getItem('i18nextLng')==='ka' ? (<span className="flag-icon flag-icon-ge mr-2"> </span>):('')}
                    { localStorage.getItem('i18nextLng')==='en' ? (<span className="flag-icon flag-icon-us mr-2"> </span>):('')}
                    { localStorage.getItem('i18nextLng')==='ru' ? (<span className="flag-icon flag-icon-ru mr-2"> </span>):('')}

                      {selected_languge}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdown09">
                    
                     <div className="dropdown-item" onClick={() => changeLanguage("ka")}><span className="flag-icon flag-icon-ge"> </span>  ქართული</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("en")}><span className="flag-icon flag-icon-us"> </span>  English</div>
                      <div className="dropdown-item" onClick={() => changeLanguage("ru")}><span className="flag-icon flag-icon-ru"> </span>  Russian</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          
        </div>
      </div>
            </div>
        );
    }
}


export default withTranslation()(menu);
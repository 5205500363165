import React from 'react';
import Home from './views/Home';
import About from './views/About';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
function App() {
  
  return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/about" component={About}/>
        </Switch>
      </Router>
  );

  
  
}

export default App;

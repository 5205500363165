import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../components/menu'
import Mobilemenu from '../components/mobilemenu'
import Footer from '../components/footer'

class About extends PureComponent {
    render() {
        const { t } = this.props;
        
        return (
            <div>
                <div className="page-wrapper">
                    <Menu/>
                    <section className="page-header">
                    <div className="page-header__bg" style={{backgroundImage: 'url(assets/images/backgrounds/page-header-bg-1-1.jpg)'}} />
                    <div className="container">
                        <ul className="thm-breadcrumb list-unstyled">
                        <li><a href="/">{t('home')}</a></li>
                        <li>/</li>
                        <li><span>{t('Aboutus')}</span></li>
                        </ul>
                        <h2>{t('Aboutus')}</h2>
                    </div>
                    </section>
                   
                    
                    <section className="about-three">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-6">
                            <div className="about-three__image">
                            <img src="assets/images/resources/about-3-2.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-three__content">
                          
                            <div className="about-three__summery">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                     adipisicing elit sed deiusmod tempor 
                                    incididunt ut labore et dolore magna
                                     aliqua. Donec scelerisque dolor id nunc
                                      dictum, interdum. 
                                      Lorem ipsum dolor sit amet, consectetur
                                     adipisicing elit sed deiusmod tempor 
                                    incididunt ut labore et dolore magna
                                     aliqua. Donec scelerisque dolor id nunc
                                      dictum, interdum. 
                                      Lorem ipsum dolor sit amet, consectetur
                                     adipisicing elit sed deiusmod tempor 
                                    incididunt ut labore et dolore magna
                                     aliqua. Donec scelerisque dolor id nunc
                                      dictum, interdum. 
                                </p>
                            </div>
                           
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="call-to-action jarallax" data-jarallax data-speed="0.3" data-imgposition="-80% 50%">
                    <img className="call-to-action__bg jarallax-img" src="assets/images/backgrounds/cta-1-bg-1.jpg" alt="parallax" />
                    <div className="container">
                        <div className="call-to-action__content">
                        <i className="call-to-action__icon agrikon-icon-agriculture-2" />
                        <h3>We’re popular leader in agriculture market globally</h3>
                        </div>
                       
                    </div>
                    </section>
                    <Footer/>
                    <Mobilemenu/>
                </div>
        </div>
        );
    }
}

export default withTranslation()(About);